<template>
    <div id="titres">
        <h1 >LES AGENCES</h1>
        <div class="container " style="max-width: 65%;">
            <div class="grid ">
                <div class="dashboard-card w-100" v-for="item in items" :key="item.id" @click="$router.push({name:'Stock',params:{name:item.name}})">
                    <div class="card-icon">
                        <i class="fas fa-3x ml-4 text-info" :class="`${item.icon}`"></i>
                    </div>
                    <div class="card-details">
                        <div class="label">{{item.name}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>


    export default {
        name: 'GestionStock',
        data(){
            return{
                items:[
                    {
                        id:1,
                        name:'PrettyLinge Boutique Sidi Ghanem',
                        icon:'fa-hotel'
                    },
                    {
                        id:2,
                        name:'PrettyLinge ShowRoom',
                        icon:'fa-hotel'
                    },
                    {
                        id:3,
                        name:'PrettyLinge M Avenue',
                        icon:'fa-hotel'
                    },

                ]
            }
        }

    }
</script>
<style scoped>

    .dashboard-card {
        box-shadow: 1px 1px 32px -17px;
        border-radius: 7px;
        padding: 1rem;
        display: flex;
        align-items: center;
        background-color: white;

        cursor: pointer;
        transition: all .2s ease-in-out;
        margin-bottom: 20px;
    }
    .dashboard-card:hover{
        transform: scale(1.1);

    }
    .grid {
        display: grid;
        grid-template-columns: repeat(3,minmax(auto, 1fr));
        grid-gap: 3rem;
        margin-bottom: 10rem;
    }
    .card-details {
        margin-left: 2rem;
    }

    h1{

        font-weight: bold;
        letter-spacing: 0.12em;
        margin-top: 100px;
        text-align: center;
        margin-bottom: 100px;

    }



    @media screen and (max-width: 900px) {
        h1{
            font-size: 24px;
            margin-top: 80px;
        }
        .grid{
            margin: auto;
            display: block;
            height: auto;
        }
        .dashboard-card{
            margin: auto;
            margin-bottom: 40px;
            padding-bottom: 10px;
        }
    }

</style>